import React, { useState, useEffect } from 'react'
import css from './style.module.scss'
import { userSession } from 'utils/auth'
import Page from 'components/Page'
import { useSelector } from 'react-redux'
import {
  addArticle,
  setArticles,
  closeCurrentArticle,
  denormalizer,
} from 'reducers/articles.js'
import { store } from 'reducers/index.js'
import cn from 'classnames'
import Button from 'components/Button'
import { Link } from '@reach/router'

const Article = ({
  title,
  author,
  description,
  image,
  source,
  wordCount,
  published,
  readAt,
  url,
  domain,
  id,
}) => {
  return (
    <div className={css.article}>
      {image && (
        <Link to={`/app/articles/${id}`}>
          <img className={css.image} src={image} />
        </Link>
      )}
      <div className={css.text}>
        <Link to={`/app/articles/${id}`} className={css.title}>
          {title}
        </Link>
        <Link to={`/app/articles/${id}`} className={css.description}>
          {description}
        </Link>
        <div className={css.more}>
          {author && (
            <div className={css.author}>
              By <b>{author}</b>
            </div>
          )}
          <a className={css.url} href={url}>
            {domain}
          </a>
        </div>
      </div>
    </div>
  )
}

const renderName = profile => {
  if (profile.name) {
    return ', ' + profile.name.split(' ')[0]
  } else {
    return ''
  }
}
const Homepage = () => {
  const user = userSession.loadUserData()
  console.log(user.profile)
  useEffect(() => {
    store.dispatch(closeCurrentArticle())
  }, [])
  const [currentView, setCurrentView] = useState('toRead')
  const allArticles = useSelector(state =>
    denormalizer(state.articles.list || {})
  )
  // const articles = []
  const articles = allArticles
    .filter(article => {
      if (currentView === 'toRead') {
        return !article.readAt
      }
      return article.readAt
    })
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
  const list = false
  if (list) {
    return (
      <ul className={cn(css.articles, css.list)}>
        {articles.map(
          article =>
            console.log('article') || (
              <li key={article.title}>{article.title}</li>
            )
        )}
      </ul>
    )
  }
  return (
    <Page withBack={false}>
      <div className={css.articlesContainer}>
        <div className={css.articlesActions}>
          <h2>
            {currentView === 'toRead'
              ? 'What do you want to read now' + renderName(user.profile) + '?'
              : 'Archive'}
          </h2>
          <div className={css.buttons}>
            <Button
              secondary
              onClick={() => {
                setCurrentView(currentView === 'toRead' ? 'archived' : 'toRead')
              }}
            >
              {currentView === 'toRead'
                ? 'View Archive'
                : 'Show articles to read'}
            </Button>
            <Button className={css.newUrlButton} to="/app/articles/new">
              Add New Url
            </Button>
          </div>
        </div>

        <div className={cn(css.articles, css.grid)}>
          {articles.length > 0 &&
            articles.map(article => <Article key={article.id} {...article} />)}

          {articles.length === 0 && currentView !== 'toRead' && (
            <div className={css.noArticles}>
              <h2>Your archive is empty.</h2>
              <br />
              <p>When you read an article, we will put it here.</p>
            </div>
          )}
          {articles.length === 0 && currentView === 'toRead' && (
            <div className={css.noArticles}>
              <h2>You have no articles to read.</h2>
              <br />
              <br />
              <p>
                You can always add one using the <strong>'Add New Url'</strong>{' '}
                button.
              </p>
              <br />
              <br />
              <br />
              <br />
              <br />
              <p>
                You can also use our Firefox extension to add new articles right
                from the web page: it's 1000 times faster.
              </p>
              <br />
              <Button to="https://addons.mozilla.org/en-US/firefox/addon/cozy-reader/">
                Add our Firefox extension
              </Button>
              <br />
              <br />
              <br />
              <br />
              <p>(Chrome Extension is coming soon! 💜)</p>
            </div>
          )}
        </div>
      </div>
    </Page>
  )
}

export default Homepage

import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import Page from 'components/Page/index.js'
import api from 'api/index.js'
import { useSelector } from 'react-redux'
import { setStyle } from 'reducers/ui.js'
import { addArticle, closeCurrentArticle } from 'reducers/articles.js'
import { store } from 'reducers/index.js'
import css from './style.module.scss'
import Button from 'components/Button'
import Input from 'components/Inputs'
import { navigate } from '@reach/router'
const urlSchema = Yup.object().shape({
  url: Yup.string()
    .url('Is this an url?')
    .required('Required'),
})

const NewUrl = ({ onClose }) => {
  const onSubmit = async (values, actions) => {
    if (actions.validateForm()) {
      actions.setSubmitting(true)
      const response = await api.parseURL({ url: values.url })
      store.dispatch(addArticle(response))
      navigate('/')
    }
  }
  return (
    <div className={css.container}>
      <Formik
        validationSchema={urlSchema}
        initialValues={{ url: '' }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, values, errors, touched }) => (
          <div style={{ width: '100%' }}>
            <h2 className={css.title}>Save a URL to read</h2>
            <form className={css.newUrlForm} onSubmit={handleSubmit}>
              <Field
                placeholder="https://..."
                component={Input}
                big
                error
                name="url"
              />
              {errors.url && touched.url && (
                <div className={css.errors}>{errors.url}</div>
              )}

              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Loading...' : 'Add URL'}
              </Button>
              <Button className={css.close} link to="/">
                Nevermind
              </Button>
            </form>
            <br />
            <br />
            <br />
            <h3 className={css.subtitle}>Add our Browser Extensions</h3>

            <p>
              You can also use our Firefox addon to add new articles right from
              the web page. Chrome extension coming soon 💜
            </p>
            <br />
            <div className={css.buttons}>
              <Button
                secondary
                to="https://addons.mozilla.org/en-US/firefox/addon/cozy-reader/"
              >
                Add our Firefox addon
              </Button>

              <Button
                secondary
                to="https://chrome.google.com/webstore/detail/cozy-reader/empcenahidblecbhiplhgjibkajndolo"
                className={css.second}
              >
                Add our Chrome extension
              </Button>
            </div>
            <br />

            <br />

            <br />

            <h3 className={css.subtitle}>Use the bookmarklet</h3>
            <p>
              Drag the bookmarklet below to your Bookmarks Bar. Then simply
              click it you want to save an article and it will be saved on Cozy.
            </p>
            <br />
            <div className={css.buttons}>
              <Button
                secondary
                external
                to="javascript:(function () {var jsCode = document.createElement('script');jsCode.setAttribute('src', 'https://cozyreader.xyz/browser/bookmarklet/script.js');document.body.appendChild(jsCode);}());"
              >
                Add to Cozy
              </Button>
              <span className={css.second}>
                ← drag this button to your Bookmarks Bar.
              </span>
            </div>
          </div>
        )}
      </Formik>
    </div>
  )
}

export default NewUrl

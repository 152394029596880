const loadStyle = style => {
  const isMobile = document.body.clientWidth < 600;
  const styles = {
    fontSize: {
      1: { fontSize: "1em" },
      2: { fontSize: "1.2em" },
      3: { fontSize: "1.4em" },
      4: { fontSize: "1.6em" },
      5: { fontSize: "1.8em" }
    },
    lineHeight: {
      1: { lineHeight: "1.2em" },
      2: { lineHeight: "1.4em" },
      3: { lineHeight: "1.6em" }
    },
    maxWidth: {
      1: { maxWidth: isMobile ? "70%" : "40%" },
      2: { maxWidth: isMobile ? "80%" : "60%" },
      3: { maxWidth: isMobile ? "90%" : "80%" }
    },
    theme: {
      dark: {
        backgroundColor: "rgb(26, 26, 26)",
        color: "rgb(224, 224, 224)"
      },
      sepia: {
        backgroundColor: "rgb(245, 237, 221)",
        color: "#666"
      },
      white: {
        backgroundColor: "rgb(255,255,255)",
        color: "#666"
      }
    }
  };

  return Object.keys(style).reduce((acc, styleOption) => {
    const s = styles[styleOption]
      ? styles[styleOption][style[styleOption]]
      : { [styleOption]: style[styleOption] };
    return { ...acc, ...s };
  }, {});
};

export default loadStyle;

import { createAction, createReducer } from 'redux-act'
import { userSession } from 'utils/auth'

export const addArticle = createAction('addArticle')
export const setArticles = createAction('setArticles')
export const deleteArticle = createAction('deleteArticle')
export const deleteArticles = createAction('deleteArticles')
export const readArticle = createAction('readArticle')
export const setCurrentArticle = createAction('setCurrentArticle')
export const closeCurrentArticle = createAction('closeCurrentArticle')

const defaultState = { list: {}, current: undefined }

export const denormalizer = objects =>
  Object.keys(objects).map(key => objects[key])
export const normalizer = list => {
  return list.reduce((objects, article) => {
    objects[article.id] = article
    return objects
  }, {})
}
export default createReducer(
  {
    [addArticle]: (state, payload) => {
      const { content, ...article } = payload
      return {
        ...state,
        list: {
          ...state.list,
          [article.id]: article,
        },
      }
    },
    [deleteArticle]: (state, payload) => {
      const list = { ...state.list }
      delete list[payload.id]
      return {
        ...state,
        list,
      }
    },
    [readArticle]: (state, payload) => {
      const article = {
        ...payload,
        readAt: payload.readAt ? undefined : new Date(),
      }
      const { content, ...strippedArticle } = article
      return {
        ...state,
        current: article,
        list: {
          ...state.list,
          [payload.id]: strippedArticle,
        },
      }
    },
    [setArticles]: (state, payload) => {
      return { ...state, list: payload }
    },
    [deleteArticles]: (state, payload) => {
      return { ...state, list: {} }
    },
    [setCurrentArticle]: (state, payload) => {
      return { ...state, current: payload }
    },
    [closeCurrentArticle]: (state, payload) => {
      return { ...state, current: undefined }
    },
  },

  defaultState
)

const saveArticles = async articles => {
  const articlesList = denormalizer(articles)
  await userSession.putFile(
    'articles.json',
    JSON.stringify(normalizer(articlesList.filter(article => !article.readAt)))
  )
  await userSession.putFile(
    'articles_archived.json',
    JSON.stringify(normalizer(articlesList.filter(article => article.readAt)))
  )
  return
}

const saveArticle = async article => {
  return await userSession.putFile(
    'articles/' + article.id + '.json',
    JSON.stringify(article)
  )
}
const delArticle = async article => {
  return await userSession.deleteFile('articles/' + article.id + '.json')
}

export const articlesSideEffects = {
  [addArticle]: async (action, state) => {
    window.metrical && window.metrical.trackEvent('article_add')
    await saveArticles(state.articles.list)
    //add the article to the general file, minus the description. add a md5 of the title to use as ID
    await saveArticle(action.payload)
    //add all the article in the file articles/{ID}.json, to obtain in the future
  },
  [readArticle]: async (action, state) => {
    window.metrical && window.metrical.trackEvent('article_read')
    await saveArticles(state.articles.list)
    //add the article to the general file, minus the description. add a md5 of the title to use as ID
    await saveArticle(state.articles.current)
  },
  [deleteArticle]: async (action, state) => {
    await saveArticles(state.articles.list)
    await delArticle(action.payload)
  },
  [deleteArticles]: async (action, state) => {
    await saveArticles(state.articles.list)
    alert('Done')
  },
}

import React, { useState, useEffect } from 'react'
import { userSession } from 'utils/auth'
import { setStyle, setFirstLoadEnded } from 'reducers/ui.js'
import { store } from 'reducers/index.js'
import Loading from 'components/Loading'
import {
  addArticle,
  setArticles,
  closeCurrentArticle,
  denormalizer,
} from 'reducers/articles.js'
import { useSelector } from 'react-redux'

const loadStyle = async () => {
  // await userSession.deleteFile("style.json");
  const response = await userSession.getFile('style.json')
  const style = response
    ? JSON.parse(response)
    : {
        fontSize: 2,
        maxWidth: 2,
        theme: 'dark',
      }

  store.dispatch(setStyle(style))
  return
}

const loadArticles = async () => {
  // await userSession.deleteFile("articles.json");
  const response = (await userSession.getFile('articles.json')) || '{}'
  const responseArticles = JSON.parse(response)
  const responseArchived =
    (await userSession.getFile('articles_archived.json')) || '{}'
  const responseArchivedArticles = JSON.parse(responseArchived)
  console.log('responseArticles', responseArticles)
  console.log(responseArticles, responseArchivedArticles)
  store.dispatch(
    setArticles({ ...responseArticles, ...responseArchivedArticles })
  )
  // };
  // store.dispatch(closeCurrentArticle());
  // getArticles();
}

const UserSignedInContainer = ({ children }) => {
  const [loaded, setLoaded] = useState(false)
  const alreadyLoaded = useSelector(state => state.ui.firstLoadEnded)
  console.log('alreadyLoaded', alreadyLoaded)
  useEffect(() => {
    const init = async () => {
      if (!alreadyLoaded) {
        await loadStyle()
        await loadArticles()
        store.dispatch(setFirstLoadEnded())
      }
      setLoaded(true)
    }
    init()
  }, [])

  return loaded ? children : <Loading expand />
}

export default UserSignedInContainer

import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import api from "api";
import { useSelector } from "react-redux";
import { setStyle, setTheme } from "reducers/ui.js";
import { store } from "reducers/index.js";
import css from "./style.module.scss";
import cn from "classnames";
import Button from "components/Button";
const FontSizeOption = ({ current, save }) => {
  console.log("current", current);
  const values = [1, 2, 3, 4, 5];
  return (
    <div className={css.option}>
      <h4>Size:</h4>
      <div className={css.indicators}>
        {values.map(
          val =>
            console.log("val", val) || (
              <button
                onClick={() => {
                  save("fontSize", val);
                }}
                key={val}
                className={cn(css.indicator, current === val && css.selected)}
              >
                {val}
              </button>
            )
        )}
      </div>
    </div>
  );
};

const MaxWidthOption = ({ current, save }) => {
  console.log("current", current);
  const values = [1, 2, 3];
  return (
    <div className={css.option}>
      <h4>Width:</h4>
      <div className={css.indicators}>
        {values.map(val => (
          <button
            onClick={() => {
              save("maxWidth", val);
            }}
            key={val}
            className={cn(css.indicator, current === val && css.selected)}
          >
            {val}
          </button>
        ))}
      </div>
    </div>
  );
};

const ThemeOption = ({ current, save }) => {
  console.log("current", current);
  const values = ["dark", "sepia", "light"];
  return (
    <div className={css.option}>
      <h4>Theme:</h4>
      <div className={css.indicators}>
        {values.map(val => (
          <button
            onClick={() => {
              save("theme", val);
              // store.dispatch(setTheme(val));
            }}
            key={val}
            className={cn(
              css.indicator,
              css.indicatorButton,
              current === val && css.selected
            )}
          >
            {val}
          </button>
        ))}
      </div>
    </div>
  );
};

const StyleMenu = ({ style }) => {
  const [open, setOpen] = useState(false);
  const saveStyle = (label, s) => {
    store.dispatch(
      setStyle({
        ...style,
        [label]: s
      })
    );
  };
  return (
    <div className={cn(css.menu, open && css.open)}>
      {!open && (
        <Button
          secondary
          onClick={() => {
            setOpen(true);
          }}
        >
          Settings
        </Button>
      )}
      {open && (
        <div>
          <FontSizeOption save={saveStyle} current={style.fontSize} />
          <MaxWidthOption save={saveStyle} current={style.maxWidth} />
          <ThemeOption save={saveStyle} current={style.theme} />
          <Button
            className={css.close}
            secondary
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </Button>
        </div>
      )}
    </div>
  );
};

export default StyleMenu;

import React, { useState, useEffect } from 'react'
import { userSession } from 'utils/auth'
import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import { useSelector } from 'react-redux'
import {
  addArticle,
  setCurrentArticle,
  readArticle,
} from 'reducers/articles.js'
import { store } from 'reducers/index.js'
import css from './style.module.scss'
import Page from 'components/Page/index.js'
import StyleMenu from './StyleMenu'
import Button from 'components/Button'
import loadStyle from 'utils/articleStyle'
import Loading from 'components/Loading'
import { Link } from '@reach/router'

const ArticlePage = ({ id }) => {
  const styleOptions = useSelector(state => state.ui.style)
  const article = useSelector(state => state.articles.current)
  const onRead = () => {
    store.dispatch(readArticle(article))
  }
  useEffect(() => {
    const getArticle = async () => {
      const response =
        (await userSession.getFile('articles/' + id + '.json')) || '{}'
      const responseArticle = JSON.parse(response)
      store.dispatch(setCurrentArticle(responseArticle))
    }
    getArticle()
  }, [])

  const style = loadStyle(styleOptions)
  if (article && article.id) {
    const { title, description, author, url, source, content, domain } = article

    return (
      <Page withHeader={false}>
        <StyleMenu style={styleOptions} />
        <div className={css.articleContainer} style={style}>
          {/* {article.image && <img className={css.image} src={article.image} />} */}
          <Link to={`/article/${id}`} className={css.title}>
            {title}
          </Link>
          {/* <p className={css.description}>{description}</p> */}
          <div className={css.more}>
            {author && (
              <div className={css.author}>
                By <b>{author}</b>
              </div>
            )}
            <a className={css.url} href={url}>
              {domain}
            </a>
          </div>
          <Button secondary={article.readAt} onClick={onRead}>
            Mark as {article.readAt ? 'unread' : 'read'}
          </Button>
          <div
            className={css.content}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <Button big secondary={article.readAt} onClick={onRead}>
            Mark as {article.readAt ? 'unread' : 'read'}
          </Button>
        </div>
      </Page>
    )
  } else {
    return <Loading expand />
  }
}

export default ArticlePage

import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import Page from 'components/Page/index.js'
import { useSelector } from 'react-redux'
import { setStyle } from 'reducers/ui.js'
import { deleteArticles } from 'reducers/articles.js'
import { store } from 'reducers/index.js'
import css from './style.module.scss'
import Button from 'components/Button'
import Input from 'components/Inputs'
import { Redirect } from '@reach/router'
const urlSchema = Yup.object().shape({
  url: Yup.string()
    .url('Is this an url?')
    .required('Required'),
})

const DebugPage = ({ onClose }) => {
  const [navigate, setNavigate] = useState(undefined)
  const onDelete = async () => {
    store.dispatch(deleteArticles())
  }
  return (
    <div>
      <Button onClick={onDelete}>Delete Articles</Button>
    </div>
  )
}

export default DebugPage

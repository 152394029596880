import React, { useRef } from "react";
import cn from "classnames";
import css from "./style.module.scss";

const isObject = function(a) {
  return !!a && a.constructor === Object;
};

export const Checkbox = ({
  name,
  selected,
  children,
  onChange,
  className,
  label
}) => {
  const onPress = () => {
    onChange(!selected);
  };
  return (
    <label className={css.checkboxWrapper} htmlFor={name}>
      <input
        id={name}
        className={cn(css.checkbox, className)}
        type="checkbox"
        checked={selected}
        onChange={onPress}
      />
      <div className={css.check} /> <span>{children || label}</span>
    </label>
  );
};

export const Label = ({ name, children }) => (
  <label htmlFor={name} className={css.label}>
    {children}
  </label>
);

export const Input = ({
  label,
  name,
  field,
  type,
  placeholder,
  value,
  onChange,
  big,
  className,
  responsive,
  description,
  errors,
  disabled
}) => (
  <div className={cn(css.input, className)}>
    {label && (
      <div className={css.labelRow}>
        <Label name={name}>{label}</Label>
        {errors && <small className={css.errors}>{errors}</small>}
      </div>
    )}
    <input
      placeholder={placeholder}
      className={cn(
        css.inputField,
        responsive && css.responsive,
        big && css.big
      )}
      id={name}
      name={name}
      disabled={disabled}
      autoComplete="off"
      value={value}
      onChange={onChange}
      type={type}
      {...field}
    />
    {description && <small className={css.description}>{description}</small>}
  </div>
);

export default Input;

import React from 'react'
import { Router } from '@reach/router'
import Profile from './profile'
import PrivateRoute from 'components/PrivateRoute'
import HomepageLoggedOut from './HomepageLoggedOut'
import ArticlePage from './ArticlePage'
import NewUrlPage from './NewUrlPage'
import DebugPage from './DebugPage'
import ArticlesPage from './ArticlesPage'
import Page from 'components/Page'

import { Provider } from 'react-redux'
import { store } from 'reducers'

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        {/* <PrivateRoute path="/profile" component={Profile} /> */}
        <PublicRoute path="/app">
          <PrivateRoute path="/articles/:id" component={ArticlePage} />
          <PrivateRoute path="/articles/new" component={NewUrlPage} />
          <PrivateRoute path="/__debug" component={DebugPage} />
          <PrivateRoute path="/" component={ArticlesPage} />
          {/* <HomepageLoggedOut path="/app" /> */}
        </PublicRoute>
      </Router>
    </Provider>
  )
}
function PublicRoute(props) {
  return <>{props.children}</>
}

export default App

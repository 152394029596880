import axios from 'axios'

// const baseURL = "https://plainweb.francesco.space";
export const parseURL = async ({ url }) => {
  const response = await axios.get('/.netlify/functions/addUrl?url=' + url)
  return response.data
}

export default {
  parseURL,
}
